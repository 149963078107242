import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="m 314.41843,471.53706 c 0.66605,28.0253 -15.54957,55.48254 -40.70786,68.0198 -25.68415,13.73768 -55.43778,10.24731 -83.48537,10.75616 -4.18296,-0.58411 -1.22336,5.97435 -2.10873,8.68783 0,27.2147 0,54.42941 0,81.64411 -10.74218,0 -21.48435,0 -32.22653,0 0,-80.78334 0,-161.56668 0,-242.35002 26.65221,0.20405 53.32368,-0.46053 79.96091,0.44235 26.26644,1.15851 54.57145,10.98306 68.94693,34.41886 7.33406,11.34667 9.64003,25.07865 9.62065,38.38091 z m -33.52861,0.8138 c 0.71205,-16.20192 -7.44113,-33.22375 -22.8462,-39.81396 -20.09098,-9.17254 -42.66081,-5.95493 -64.08962,-6.57272 -4.04166,-0.55131 -7.29754,-0.31685 -5.83753,4.63351 0,30.73628 0,61.47257 0,92.20885 21.62003,-0.58493 44.06561,2.31216 64.7379,-5.46264 17.98026,-6.99235 28.69805,-26.11237 28.03545,-44.99304 z"
          id="path3354"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
